import { AdmissionFormState } from '../../dashboard/store/admission-form/admission-form.state';
import { DocumentsListAdmission, SaveDraftPOC, SetAction } from '../../dashboard/store/admission-form/admission-form.actions';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { Select, Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { AdmissionDocumentsEnums, RoleEnum, TypeApplication } from '../../shared/models';
import dayJs from 'dayjs';
import { ReportStatusEnum } from '../../shared/enums/other.enums';
import { MaskDirective } from '../../shared/directives/mask.directive';
import { AdmissionTypeEnum } from '../../shared/enums/admission.enum';
import { PermissionAccessService } from '../../shared/services/permission-access.service';
import { PermissionAdmissionEnum } from '../../shared/enums/permission.enum';
import { RoleOnlyDirective } from '../../shared/directives/role-only.directive';
import { AdmissionDocumentsModel } from '../../shared/models/admission-documents.model';
import { rmDuplicatesElementsByKey } from '../../shared/helpers/other';
import { FormControlPipe } from '../../dashboard/form-control.pipe';
import { PHONE_MASK } from '../../shared/helpers/phone-mask';
import { UserService } from '../../dashboard/services/user.service';
import { dcwFieldsValidators } from '../../shared/validators/dcw-fieds.validator';
import { removeAllError } from '../../shared/helpers/removeAllError';

enum AllDocumentsValueEnum {
  NotAllSelected = 0,
  AllAdmission = 1,
  AllSR = 2,
  AllAdmissionsAndSR = 3,
}

@Component({
  selector: 'app-choosed-documents-admission',
  templateUrl: './choose-documents-admission.component.html',
  styleUrls: ['./choose-documents-admission.component.scss'],
})
export class ChooseDocumentsAdmissionComponent implements OnInit, OnDestroy {
  @Input() isReport = false;
  @Input() isDisableSendAction = false;
  @Input() disableAllButtons = false;
  @Input() id = '';
  @Input() typeOfApplication: AdmissionTypeEnum;
  @Input() withAdmission = false;
  @Input() checkedDocs = null;
  @Input() status: number;
  @Input() createdAt: string;
  @Input() isPOC = false;
  @Input() hasSignedAdmission = false;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() nextStep: any = new EventEmitter();
  @Output() actionSign: any = new EventEmitter();
  @Output() backToPopUp: any = new EventEmitter();
  @Select(AdmissionFormState.listAdmissionDocuments) listAdmissionDocuments$: Observable<any[]>;
  @Select(AdmissionFormState.listSentDocument) listSentDocument$: Observable<any[]>;
  @Select(AdmissionFormState.formValue) formValue$: Observable<any>;
  @Select(AdmissionFormState.dischargeSummary) dischargeSummary$: Observable<any>;
  @Select(AdmissionFormState.draftPOC) draftPOC$: Observable<any>;
  dataAddNewDoc = '10/18/2024';
  closeDropdown = new EventEmitter();
  dcwsList$ = new BehaviorSubject([]);
  supervisorList$ = new BehaviorSubject([]);
  dcwsItems = [];
  caregiversList$ = new BehaviorSubject([]);
  caregivers = [];
  supervisor = [];
  searchSubjectCaregiver$: BehaviorSubject<any> = new BehaviorSubject(false);
  searchSubjectSupervisor$: BehaviorSubject<any> = new BehaviorSubject(false);
  next = false;
  documents: AdmissionDocumentsModel[] = null;
  allSelectedAdmission = false;
  allSelectedSR = false;
  activePhoneField = false;
  isSendDocs = false;
  dataSend = {};
  filter = '';
  phoneMask = PHONE_MASK;
  errors: any[] = [];
  isFirstInit = !this.isPOC;
  dcwFormGroup: FormGroup = this.fb.group({
    dcw: this.fb.array([]),
    supervisor: this.fb.group({
      email: '',
      phone: '',
      uid: '',
      actions: 'email',
      name: null as string,
      complexName: '',
      isSupervisor: true,
    }),
  });
  public sentDocks: AdmissionDocumentsModel[] = [];
  public hideSelectAll = false;
  public AdmissionTypeEnum = AdmissionTypeEnum;
  RoleEnum = RoleEnum;
  created = null;
  showCalendars: any = {
    created: false,
  };
  role = this.store.selectSnapshot(({ app }) => app.currentUser?.roles[0]?.title);
  uncheckAdmissionPackage = false;
  uncheckSRPackage = false;
  search = '';
  public haveCriminalForm: any = this.fb.group({
    have: [null as boolean, Validators.required],
    caregivers: this.fb.array([]),
  });
  otherInstruction = this.fb.control('', Validators.maxLength(150));
  protected readonly PermissionAdmissionEnum = PermissionAdmissionEnum;
  protected readonly TypeApplication = TypeApplication;
  private destroy$: ReplaySubject<void> = new ReplaySubject(1);
  private canBeSupervisor;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private permissionService: PermissionAccessService,
    private fb: FormBuilder,
    private userService: UserService,
  ) {}

  _errorSend = {};

  get errorSend(): { [key: string]: string | [string] } {
    return this._errorSend;
  }

  @Input() set errorSend(value) {
    if (value) {
      this._errorSend = value;
      this.checkError(value);
    }
  }

  get selectedDocs(): any[] {
    return rmDuplicatesElementsByKey(
      this.documents.filter((doc: AdmissionDocumentsModel) => doc.checked),
      'key',
    );
  }

  get dcwArray() {
    return <FormArray>this.dcwFormGroup.get('dcw');
  }

  get caregiversArray() {
    return <FormArray>this.haveCriminalForm.get('caregivers');
  }

  get chosenDCW() {
    return this.dcwArray.value.filter(i => i.uid);
  }

  ngOnInit(): void {
    this.permissionService
      .hasPermission(PermissionAdmissionEnum.CanBeSupervisor)
      .pipe(take(1))
      .subscribe((value: boolean) => {
        this.canBeSupervisor = value;
      });
    this.listenDocumentChanges();
    this.patchCreatedField();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.created = null;
    this.isDisableSendAction = false;
    this.disableAllButtons = false;
  }

  listenDocumentChanges() {
    combineLatest([this.store.dispatch(new DocumentsListAdmission(this.isPOC ? 'poc' : this.id)), this.listSentDocument$])
      .pipe(take(1))
      .subscribe({
        next: ([documents, sentDocuments]) => {
          const data = documents.admissionForm.listAdmissionDocuments;
          this.documents = data.map((item: AdmissionDocumentsModel) => {
            if (item.key === AdmissionDocumentsEnums.HomemakerSupervisoryReport) {
              return { ...item, checked: true };
            }
            if (item.key === AdmissionDocumentsEnums.DischargeTransferSummaryForm) {
              return { ...item, checked: true };
            }
            if (sentDocuments?.find((doc): boolean => doc === item.key)) {
              return { ...item, checked: true };
            } else {
              return { ...item, checked: false };
            }
          });

          this.sentDocks = JSON.parse(JSON.stringify(this.documents));
          if (this.sentDocks.length) {
            this.hideSelectAll = this.sentDocks.reduce((acc: boolean, item: AdmissionDocumentsModel): boolean => {
              return !item.checked ? item.checked : acc;
            }, true);
          }
          if (this.documents.length) {
            this.allSelectedAdmission = this.uncheckAdmissionPackage = this.documents.filter(
              (doc: AdmissionDocumentsModel): boolean => doc.type === 'admission',
            )?.length
              ? this.documents
                  .filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'admission')
                  .filter((doc: AdmissionDocumentsModel): boolean => {
                    if (dayJs(this.createdAt).isBefore(dayJs(this.dataAddNewDoc))) {
                      return doc.key !== 'HealthShareExchangePrivacyForm';
                    } else {
                      return true;
                    }
                  })
                  .every((doc: AdmissionDocumentsModel) => doc.checked)
              : false;
            this.allSelectedSR = this.uncheckSRPackage = this.documents.filter(
              (doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report',
            )?.length
              ? this.documents
                  .filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report')
                  .every((doc: AdmissionDocumentsModel) => doc.checked)
              : false;
          }
          if (this.documents.length === 1) {
            this.toggle(this.documents[0], true);
            this.toggleStep(true);
          }
          this.cd.detectChanges();
        },
        error: () => {
          this.documents = [];
        },
      });
  }

  isDisabledChange(item): boolean {
    if (item.key === AdmissionDocumentsEnums.HomemakerSupervisoryReport) {
      return true;
    }
    if (this.typeOfApplication === AdmissionTypeEnum.Admission || this.typeOfApplication === AdmissionTypeEnum.AdmissionInt) {
      if (dayJs(this.createdAt).isBefore(dayJs(this.dataAddNewDoc))) {
        return (
          item.key !== 'HealthShareExchangePrivacyForm' &&
          !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked
        );
      } else {
        return !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked;
      }
    }
    if (this.canBeSupervisor && this.status === ReportStatusEnum.ToBeCorrected) {
      return !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked;
    } else {
      if (this.status !== ReportStatusEnum.Signed && this.status !== ReportStatusEnum.SentForSignatures) {
        return false;
      } else {
        return !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked;
      }
    }
  }

  selectAll(typeOfApplication): void {
    if (typeOfApplication === AdmissionTypeEnum.Admission) {
      this.allSelectedAdmission = !this.allSelectedAdmission;
      this.documents = this.documents.map((doc: AdmissionDocumentsModel) => {
        if (this.isDisabledChange(doc) || doc.type === 'supervisory_report') {
          return doc;
        } else {
          return { ...doc, checked: this.allSelectedAdmission };
        }
      });
    } else {
      this.allSelectedSR = !this.allSelectedSR;
      this.documents = this.documents.map((doc: AdmissionDocumentsModel) => {
        if (this.isDisabledChange(doc) || doc.type === 'admission') {
          return doc;
        } else {
          return { ...doc, checked: this.allSelectedSR };
        }
      });
    }
  }

  toggle(document: AdmissionDocumentsModel, value: boolean = false): void {
    const currentDoc: AdmissionDocumentsModel = this.documents.find(
      (doc: AdmissionDocumentsModel) => doc.type === document.type && doc.key === document.key,
    );
    currentDoc.checked = value ? value : !currentDoc.checked;
    this.allSelectedAdmission = this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'admission' && doc.checked).length
      ? this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'admission' && doc.checked)?.length ===
        this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'admission')?.length
      : false;
    this.allSelectedSR = this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'supervisory_report' && doc.checked)?.length
      ? this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'supervisory_report' && doc.checked)?.length ===
        this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report')?.length
      : false;
  }

  chooseAction(action): void {
    if (action === 'sign') {
      const dcwValue = this.dcwFormGroup.value;
      this.dataSend = {
        isFromAction: true,
        keys: this.selectedDocs.map((item: AdmissionDocumentsModel) => item.key),
        isAllDocuments: this.getIsAllDocumentsValue(),
        createdAt: dayJs(this.created).isValid() ? dayJs(this.created).format('YYYY-MM-DD') : null,
      };
      if (this.typeOfApplication === AdmissionTypeEnum.POC || this.typeOfApplication === AdmissionTypeEnum.POCInt) {
        this.dataSend['placeForSend'] = dcwValue;
      }
      this.store.dispatch(new SetAction({ name: 'actionSetStarted', data: this.dataSend }));
      this.activePhoneField = false;
    }
  }

  sendDocs() {
    const haveCriminal = this.haveCriminalForm.value;
    if (haveCriminal.have && haveCriminal.caregivers?.length) {
      haveCriminal.caregivers = haveCriminal.caregivers.map(i => i[0]);
    }
    const dcwValue = this.dcwFormGroup.value;
    this.dataSend = {
      placeForSend: dcwValue,
      haveCriminal,
      keys: this.selectedDocs.map((item: AdmissionDocumentsModel) => item.key),
      isAllDocuments: this.getIsAllDocumentsValue(),
      createdAt: dayJs(this.created).isValid() ? dayJs(this.created).format('YYYY-MM-DD') : null,
      otherInstructions: this.otherInstruction.value,
    };

    this.nextStep.emit(this.dataSend);
    this.store.dispatch(new SetAction({ name: 'sentDocs', data: this.dataSend }));
  }

  toggleStep(value: boolean = false): void {
    this.filter = '';
    if (this.documents.filter((doc: AdmissionDocumentsModel) => doc.checked).length) {
      this.next = value ? value : !this.next;
    }
  }

  showDoc(docName): boolean {
    return this.filter === '' || docName.toLowerCase().includes(this.filter.toLowerCase());
  }

  // CAREGIVERS BLOC
  initForm() {
    if (!this.hasSignedAdmission || !this.isPOC) {
      this.dcwFormGroup.addControl(
        'patient',
        this.fb.group(
          {
            patientEmail: '',
            patientPhone: '',
            actions: 'email',
          },
          { validators: dcwFieldsValidators(true, 'patientEmail', 'patientPhone') },
        ),
      );
    }

    this.draftPOC$.pipe(take(1)).subscribe(value => {
      if (this.typeOfApplication === AdmissionTypeEnum.POC || this.typeOfApplication === AdmissionTypeEnum.POCInt) {
        const supervisor = value.caregivers.find(i => i.isSupervisor);
        if (supervisor) {
          this.dcwFormGroup.get('supervisor').patchValue({
            email: supervisor.email,
            phone: supervisor.phone,
            uid: supervisor.uid,
            actions: 'email',
            name: supervisor.name,
            complexName: supervisor.complexName,
            isSupervisor: supervisor.isSupervisor,
          });
          this.supervisorList$.next([supervisor]);
          value.caregivers = value.caregivers.filter(i => !i.isSupervisor);
        }
        value.caregivers.forEach((car, i) => {
          if (!car.isSupervisor) {
            (<FormArray>this.dcwFormGroup.controls['dcw']).push(
              this.fb.group(
                {
                  email: car.email,
                  phone: car.phone,
                  uid: car.uid,
                  actions: 'email',
                  name: car.name,
                  complexName: car.complexName,
                  isSupervisor: car.isSupervisor,
                },
                { validators: dcwFieldsValidators(this.isPOC) },
              ),
            );
            this.dcwsItems[i] = [car];
          }
        });
        if (value.patient) {
          this.dcwFormGroup?.get('patient')?.get('patientEmail')?.setValue(value.patient.patientEmail);
          this.dcwFormGroup?.get('patient')?.get('patientPhone')?.setValue(value.patientPhone);
        }
      } else if (!this.dcwArray.length) {
        (<FormArray>this.dcwFormGroup.controls['dcw']).push(
          this.fb.group(
            {
              email: null,
              phone: null,
              uid: [null, Validators.required],
              name: null,
              complexName: null,
              isSupervisor: false,
            },
            { validators: dcwFieldsValidators(this.isPOC) },
          ),
        );
        this.dcwsItems[0] = [];
      }
      this.dcwsList$.next(this.dcwsItems);
      this.subscribeToSearchCaregivers();
      this.subscribeToSearchSupervisor();
    });
  }

  checkError(error) {
    Object.entries(error || {}).forEach(([key, value]) => {
      const keyPath = key?.split('.');
      if (key.startsWith('caregivers')) {
        if (+keyPath[1] === 0 && this.isPOC) {
          (<FormArray>this.dcwFormGroup.get('dcw'))?.controls?.[keyPath[1]]?.controls[keyPath[2]]?.setErrors({ message: value[0] });
        } else if (keyPath[1]) {
          (<FormArray>this.dcwFormGroup.get('dcw'))?.controls?.[keyPath[1]]?.controls[keyPath[2]]?.setErrors({
            message: value[0],
          });
        }
      }
      if (key.startsWith('haveCriminal')) {
        this.caregiversArray.controls[keyPath[2]].setErrors({ message: value[0] });
      }
      if (key === 'email' || key === 'patientEmail') {
        this.dcwFormGroup.get('patient').get('patientEmail').setErrors({ message: value[0] });
      }
      if (key === 'phone' || key === 'patientPhone') {
        this.dcwFormGroup.get('patient').get('patientPhone').setErrors({ message: value[0] });
      }
      if (key.startsWith('supervisor') && keyPath[1]) {
        this.dcwFormGroup.get('supervisor').get[keyPath[1]].setErrors({ message: value[0] });
      }
    });
  }

  // DCW BLOCk
  addDCW(item = null) {
    if (item) {
      (<FormArray>this.dcwFormGroup.controls['dcw']).push(
        this.fb.group(
          {
            email: item.email,
            phone: item.phone,
            uid: item.uid,
            actions: 'email',
            name: item.name,
            disabled: false,
            complexName: item.complexName,
            isSupervisor: item.isSupervisor,
          },
          { validators: dcwFieldsValidators(this.isPOC) },
        ),
      );
    } else {
      (<FormArray>this.dcwFormGroup.controls['dcw']).push(
        this.fb.group(
          {
            email: null,
            phone: null,
            uid: null,
            actions: 'email',
            name: null,
            disabled: false,
            complexName: null,
            isSupervisor: false,
          },
          { validators: dcwFieldsValidators(this.isPOC) },
        ),
      );
      const i = this.dcwsItems.length ? this.dcwsItems.length - 1 : 0;
      this.dcwsList$.pipe(take(1)).subscribe(res => {
        if (!res?.[i]?.length) {
          this.initListdcws(i);
        }
      });
    }
  }

  removeDCW(i) {
    if (
      i === 0 &&
      this.dcwArray.controls.length === 1 &&
      (!this.hasSignedAdmission || (this.hasSignedAdmission && !this.dcwFormGroup.value['supervisor']['uid']))
    ) {
      (<FormArray>this.dcwFormGroup.controls['dcw']).controls[0].reset({
        email: null,
        phone: null,
        uid: null,
        actions: 'email',
        name: null,
        complexName: null,
        isSupervisor: true,
      });
    } else {
      (<FormArray>this.dcwFormGroup.controls['dcw']).removeAt(i);
      this.dcwsItems.splice(i, 1);
      this.dcwsList$.next(this.dcwsItems);
    }
    removeAllError(this.dcwArray);
  }

  selectDCW(event, i) {
    if (event) {
      (<FormArray>this.dcwFormGroup.get('dcw')).controls[i].patchValue({
        ...event,
        action: 'email',
      });
      this.dcwsItems[i] = [event];
      this.dcwsList$.next(JSON.parse(JSON.stringify(this.dcwsItems)));
      this.haveCriminalForm.get('have').setErrors(null);
      if (i === 0 && this.haveCriminalForm.get('have').value) {
        this.addCaregiver();
      }
      removeAllError(this.dcwArray);
      (<FormArray>this.dcwFormGroup.get('dcw')).controls[i].updateValueAndValidity();
      this.cd.detectChanges();
    }
  }

  selectSupervisor(event) {
    if (event) {
      this.dcwFormGroup.get('supervisor').patchValue({
        ...event,
        action: 'email',
      });
      this.supervisorList$.next([event]);
    }
  }

  onSearch(event, i: number, type: 'onlySupervisors' | 'onlyEmployee' = 'onlyEmployee'): void {
    this.search = event.term;
    const data = {
      caregiver: event.term,
      index: i,
      type,
    };
    this.searchSubjectCaregiver$.next(data);
  }

  onSearchSupervisor(event): void {
    this.search = event.term;
    const data = event.term;
    this.searchSubjectSupervisor$.next(data);
  }

  setNewFields(i, fieldName: string, value): void {
    if (i === 'patient' || i === 'supervisor') {
      this.isSendDocs = true;
      (<FormGroup>this.dcwFormGroup.get(i)).get(fieldName).setValue(value);
    } else {
      (<FormArray>this.dcwFormGroup.get('dcw')).controls[i].get(fieldName).setValue(value);
      if (fieldName === 'actions') {
        (<FormArray>this.dcwFormGroup.get('dcw')).controls[i].get(value).markAsTouched();
      }
    }
  }

  setFormValue(form, field, value, haveCriminalFormHave: boolean = false) {
    if (haveCriminalFormHave && !this.dcwArray.controls[0]?.value?.uid) {
      this.haveCriminalForm.get('have').setErrors({ message: 'Please choose one or more DCW to a visit or choose No' });
    } else {
      if (haveCriminalFormHave && !this.caregiversArray.controls.length) {
        this.addCaregiver();
      }
      if (!haveCriminalFormHave) {
        this.caregiversArray.reset();
      }
      form.get(field).setErrors(null);
      return form.get(field).patchValue(value);
    }
  }

  addCaregiver(uid: string | string[] = '', fromSave = false): void {
    const formArray = this.caregiversArray;
    formArray.push(this.fb.control(uid, Validators.required));
    const i = this.caregiversArray.length ? this.caregiversArray.length - 1 : 0;
    this.caregiversList$.pipe(take(1)).subscribe(res => {
      if (!res?.[i]?.length) {
        this.initListCaregivers(i, fromSave);
      }
    });
  }

  deleteCaregiver(index: number): void {
    if (index === 0) {
      this.caregiversArray.controls[0].patchValue('');
    } else {
      this.caregiversArray.removeAt(index);
    }
    removeAllError(this.caregiversArray);
  }

  clear(i) {
    (<FormArray>this.dcwFormGroup.controls['dcw']).controls[i].patchValue({
      email: null,
      phone: null,
      uid: null,
      actions: 'email',
      name: null,
      complexName: null,
      isSupervisor: i === 0,
    });
    this.dcwsItems[i] = [];
    this.dcwsList$.next(JSON.parse(JSON.stringify(this.dcwsItems)));
  }

  clearSupervisor() {
    this.dcwFormGroup.get('supervisor').patchValue({
      uid: null,
      email: '',
      phone: '',
      name: null,
      actions: 'email',
    });
    this.supervisorList$.next([]);
    if (!this.dcwArray.controls.length) {
      this.addDCW();
    }
  }

  getDisableSendSign() {
    if (!this.hasSignedAdmission) {
      return (
        !this.dcwFormGroup.value?.['supervisor']?.['uid'] || !this.dcwFormGroup.value?.['dcw']?.length || !this.dcwFormGroup.value?.['dcw'][0].uid
      );
    } else if (this.hasSignedAdmission) {
      return (
        !this.dcwFormGroup.value?.['supervisor']?.['uid'] && (!this.dcwFormGroup.value?.['dcw']?.length || !this.dcwFormGroup.value?.['dcw'][0].uid)
      );
    }
  }

  backToAddPOCPopUp() {
    const { dcw, supervisor, patient } = this.dcwFormGroup.value;
    const caregivers = dcw ? dcw : [];
    if (supervisor.uid) {
      caregivers.push({ ...supervisor, isSupervisor: true });
    }
    this.store.dispatch(new SaveDraftPOC({ caregivers, patient }));
    this.backToPopUp.emit();
  }

  private init(): void {
    if (this.isFirstInit) {
      this.haveCriminalForm
        .get('have')
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe(value => {
          if (value) {
            if (this.caregiversArray.length === 0 && this.dcwArray.value?.[0].uid) {
              this.addCaregiver();
            }
          }
          if (!value.have) {
            this.caregiversArray.clear();
            this.caregiversArray.clearValidators();
          }
        });
      this.isFirstInit = false;
    }
  }

  private initListCaregivers(i: number, fromSave): void {
    if (this.caregivers?.length <= i && !fromSave) {
      this.caregivers.push(this.dcwArray.value);
    }
    this.caregiversList$.next(JSON.parse(JSON.stringify(this.caregivers)));
  }

  private initListdcws(i, fromSave?): void {
    if (this.dcwsItems.length <= i) {
      this.dcwsItems.push([]);
    }
    if (!fromSave) {
      this.dcwsItems[i] = [];
      this.dcwsList$.next(JSON.parse(JSON.stringify(this.dcwsItems)));
    } else {
      if (fromSave) {
        if (this.dcwsItems[i].length < 10) {
          fromSave.forEach(caregiverItem => {
            if (
              !this.dcwsItems[i].find((item): boolean => {
                return item?.uid === caregiverItem.uid;
              })
            ) {
              if (this.dcwsItems[i].length < 10) {
                if (caregiverItem?.uid || caregiverItem?.name) {
                  this.dcwsItems[i].push(caregiverItem);
                }
              }
            }
          });
        }
        this.dcwsList$.next(JSON.parse(JSON.stringify(this.dcwsItems)));
      }
    }
  }

  private subscribeToSearchCaregivers() {
    this.searchSubjectCaregiver$
      .pipe(
        filter(res => !!res),
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntil(this.destroy$),
      )
      .subscribe(value => {
        if (value.caregiver.length > 2) {
          this.userService
            .getUsersForPOC(value.caregiver, value.type)
            .pipe(take(1))
            .subscribe(data => {
              this.dcwsItems[value.index] = data.map(item => {
                return { ...item };
              });
              this.dcwsList$.next(this.dcwsItems);
            });
        } else {
          this.dcwsList$.next((this.dcwsItems[value.index] = []));
        }
      });
  }

  private subscribeToSearchSupervisor() {
    this.searchSubjectSupervisor$
      .pipe(
        filter(res => !!res),
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntil(this.destroy$),
      )
      .subscribe(value => {
        this.userService
          .getUsersForPOC(value.caregiver, 'onlySupervisors')
          .pipe(take(1))
          .subscribe(data => {
            this.supervisorList$.next(data);
          });
      });
  }

  private patchCreatedField(): void {
    if (this.typeOfApplication === AdmissionTypeEnum.DischargeSummary) {
      this.dischargeSummary$.pipe(take(1)).subscribe(form => {
        this.created = dayJs(form.createdAt).isValid() ? dayJs(form.createdAt).format('MM/DD/YYYY') : '';
      });
    } else {
      this.formValue$.pipe(take(1)).subscribe((formValue: any) => {
        this.created = dayJs(formValue.createdAt).isValid() ? dayJs(formValue.createdAt).format('MM/DD/YYYY') : '';
        if (
          this.typeOfApplication === AdmissionTypeEnum.Admission ||
          this.typeOfApplication === AdmissionTypeEnum.AdmissionInt ||
          this.typeOfApplication === AdmissionTypeEnum.AdditionalDocuments ||
          this.typeOfApplication === AdmissionTypeEnum.AdditionalDocumentsInt ||
          this.typeOfApplication === AdmissionTypeEnum.SupervisorReportInt ||
          this.typeOfApplication === AdmissionTypeEnum.SupervisorReport
        ) {
          this.haveCriminalForm.get('have').patchValue(formValue.haveCriminal.have);
          if (formValue.haveCriminal.have) {
            if ((!formValue.haveCriminal?.caregivers || formValue.haveCriminal?.caregivers?.length === 0) && formValue.dwcs.length) {
              formValue.dwcs.forEach(() => {
                this.caregivers[0] = formValue.dwcs;
              });
              this.addCaregiver('', true);
            } else {
              formValue.haveCriminal.caregivers.forEach((item, index) => {
                this.caregivers[index] = [{ ...item, checked: true }];
                this.addCaregiver([item.uid], true);
              });
            }
          }
          this.init();
        }
        if (
          this.typeOfApplication === AdmissionTypeEnum.Admission ||
          this.typeOfApplication === AdmissionTypeEnum.AdmissionInt ||
          this.typeOfApplication === AdmissionTypeEnum.AdditionalDocuments ||
          this.typeOfApplication === AdmissionTypeEnum.AdditionalDocumentsInt
        ) {
          if (formValue?.dcws?.length) {
            formValue.dcws.forEach((car, index) => {
              (<FormArray>this.dcwFormGroup.controls['dcw']).push(
                this.fb.group(
                  {
                    email: car.email,
                    phone: car.phone,
                    uid: car.uid,
                    actions: 'email',
                    name: car.name,
                    complexName: car.complexName,
                    disabled: true,
                    isSupervisor: car.isSupervisor,
                  },
                  { validators: dcwFieldsValidators(this.isPOC) },
                ),
              );
              this.dcwsItems[index] = [{ ...car, disabled: true }];
            });
          }
          this.otherInstruction.patchValue(formValue?.personalCareAidePlan?.otherInstructions);
        }
      });
    }
  }

  private getIsAllDocumentsValue(): AllDocumentsValueEnum {
    if (this.allSelectedAdmission && this.allSelectedSR) {
      return AllDocumentsValueEnum.AllAdmissionsAndSR;
    }
    if (this.allSelectedAdmission && !this.allSelectedSR) {
      return AllDocumentsValueEnum.AllAdmission;
    }
    if (!this.allSelectedAdmission && this.allSelectedSR) {
      return AllDocumentsValueEnum.AllSR;
    }
    if (!this.allSelectedAdmission && !this.allSelectedSR) {
      return AllDocumentsValueEnum.NotAllSelected;
    }
  }
}

@NgModule({
  declarations: [ChooseDocumentsAdmissionComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaskDirective, SharedModule, RoleOnlyDirective, FormControlPipe],
  exports: [ChooseDocumentsAdmissionComponent],
  providers: [],
})
export class ChooseDocumentsAdmissionModule {}
